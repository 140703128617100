@import '../../../variables/variables.scss';
@import './lightDetails';
@import './CatalogItem';

body {
    padding-bottom: 0 !important; // ! overrides core index.scss
}

.room-details-page {
    &__page-container {
        padding-top: 10vh;
        display: flex;
        background-color: #ecebe7;
    }

    &__panel-wrapper {
        padding: 0 24px 12px;
        min-height: 89vh;
        width: 100%;

        &.left {
            width: 30%;
            border-right: 1px solid #ada9a9ab;
            background-color: #ecebe7;
        }

        &.right {
            width: 70%;
        }
    }

    @media screen and (max-width: 800px) {
        &__page-container {
            flex-direction: column;
            height: auto;
            overflow: scroll;
            padding: 10vh 6px;
        }

        &__panel-wrapper {
            padding: 0;

            &.left {
                width: 100% !important;
                border-right: none;
                border-bottom: 3px solid black;
            }

            &.right {
                width: 100% !important;
            }
        }
    }
}

// ** Old styling **
.room-details-container {
    background-color: white;
    padding: 0;
}

.room-details-top {
    display: inline-flex;
}

.room-details-close-button {
    font-size: 20px;
    margin: 10px;
}

.room-details-close-button:hover {
    cursor: pointer;
}

.room-details-project-name {
    font-size: 12px;
    padding-left: 300px;
}

.room-details-room-name-bar {
    margin-right: 0px;
    display: inline-flex;
    width: 350px;
    justify-content: space-evenly;
}

.room-description-light-divider {
    border-bottom: 1px solid #ada9a9ab;
    margin-right: 20px;
    margin-left: 20px;
}

.room-description-no-lights {
    display: block;
    align-self: center;
    align-content: center;
    padding-left: 35%;
    padding-top: 15%;
}

// ********* Style for project container ************

.roomDetail-container {
    border-right: 1px solid #ada9a9ab;
    height: 90vh;
}

.project-date {
    h3 {
        font-size: 22px;
    }

    p {
        font-size: 13px;
        color: $primary-bg-white-sub-text;
    }
}

.project-name {
    .room-details-circle-icon {
        margin-left: 0.5rem;
        font-size: 18px;
    }

    .project-tag {
        font-size: 12px;
        color: $primary-bg-white-sub-text;
    }
}

.description-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    h4 {
        margin-left: 20px;
        font-weight: 400;
    }

    p {
        padding-left: 10px;
        color: $primary-bg-white-sub-text;
    }
}

.light-number {
    margin-left: 20px;
    font-weight: 500;
}

.container-no-lights {
    height: 200px;
    color: $primary-bg-white-sub-text;
}

.collapse-button {
    cursor: pointer;
    font-weight: 500;
    padding-right: 20px;

    span {
        font-size: 20px;
        padding-left: 10px;
    }
}

.icon-container {
    font-size: 0.7em;
    .edit-icon,
    .archive-icon,
    .clone-icon {
        color: $primary-bg;
    }
}

.collapse-content {
    background-color: #e3ddc6;
    font-size: 0.6em;
    text-wrap: nowrap;
}

.container-for-light-cards-off {
    min-height: 510px;
    max-height: 510px;
}
.room-detail-section {
    border-bottom: solid 1px $primary-bg-light-grey;
    padding-bottom: 1.5vh;
}
.container-for-light-cards {
    .single-room-container {
        background-color: $primary-bg-dark;

        // **** the top part of the light card *****
        .first-light-section {
            .first-section-name {
                padding-left: 20px;

                p {
                    color: $primary-bg-white-sub-text;
                }

                h3 {
                    color: $primary-bg-dark-title;
                }

                h4 {
                    color: $primary-bg-dark-title;
                }
            }

            .qty {
                font-weight: 600;
                font-size: 13px;

                span {
                    color: $primary-bg-white-sub-text;
                }
            }
        }

        .lightImg {
            box-shadow: $box-shadow;
            border-radius: 0.2rem;
            height: auto;
            width: 100%;
        }
    }
}

// ********* The Bottom Part Of The Light Card *********
.second-left-section {
    font-size: $primary-text-fs;

    h5:nth-child(2) {
        color: gray;
    }
}

.second-right-section {
    font-size: $primary-text-fs;

    h5:nth-child(2) {
        color: gray;
    }
}

.delete-Modal-container {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .delete-modal-button {
        background-color: red !important;
        padding: 10px 12px 10px 12px;
        width: 50%;
    }
}

.room-icons {
    cursor: pointer;
}

// ******** for the containers for each component
.details-container {
    padding-top: 10vh !important;
}

// ******** Filter Componet *********
.filter-lights-btn {
    background: transparent;
    border: none;
    cursor: pointer;
}

.filter-bar-background {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    cursor: pointer;
}

// ******** Media for Tablet ************************

@include media-lg {
    .container-for-light-cards {
        padding: 5px;
    }

    .container-for-light-cards-off {
        display: none;
    }

    .roomDetail-container {
        border-right: none;
        height: 45vh;
    }

    .details-container {
        height: 100vh;
    }
}
