@import '../../../variables/variables.scss';

.light-details {
    padding-top: 18px;
    min-width: 70vw;

    &__heading {
        display: flex;
        justify-content: space-between;
        grid-area: heading;

        &.edit-prompt {
            text-align: end;

            &__room-name {
                text-decoration: underline;
                padding-left: 6px;
            }
        }
    }

    &__carousel-wrapper {
        display: inline-block;
        width: 50%;
        grid-area: carousel;
    }

    &__description-wrapper {
        display: inline-block;
        width: 50%;
        vertical-align: top;
        grid-area: description;
    }

    &__form-wrapper {
        grid-area: form;
    }

    &__item-name {
        display: block;
        font-size: 2rem;
        margin: 0;
    }

    &__item-id {
        color: $primary-bg-white-sub-text;
    }

    @media screen and (max-width: 1200px) {
        &__content {
            display: flex;
            flex-direction: column-reverse;
        }

        &__description-wrapper,
        &__carousel-wrapper {
            display: block;
            width: 100%;
        }
    }
}

.light-details__content {
    .single-view-container {
        margin-top: 1rem;
    }
}
