// ******** top of the container **********

.light-top-catalog {
    p {
        color: $primary-bg-white-sub-text;
        font-size: 14px;
        padding-left: 26px;
    }
}

// ********* LightCard **************
.lightCard {
    img {
        padding: 0;
        height: auto;
        width: auto;
        max-width: 265px;
        max-height: 300px;
        box-shadow: #00000029 0px 2px 4px, #0000003b 0px 2px 4px;
        border-top-left-radius: 0.2rem;
        border-top-right-radius: 0.2rem;
    }
}

.pagination_ {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.pagination_ .text {
    opacity: 0.6;
    font-size: 14px;
    text-align: center;
}

.page {
    height: 3rem;
    width: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border: none;
    background-color: transparent;
    border-radius: 0.7rem;
    cursor: pointer;
}

.page.active,
.page:hover {
    color: #c6ad7c;
    padding-bottom: 0 !important;
}

.page.disabled {
    background-color: transparent !important;
    cursor: not-allowed;
    opacity: 0.5;
}

.item {
    height: 20vh;
    width: auto;
    cursor: pointer;

    &:hover {
        transition: all 0.2s ease-in-out;
        img {
            transition: all 0.2s ease-in-out;
            opacity: 0.4;
        }
        background-color: $primary-bg-dark;
    }
}

.item-cards {
    background-color: #f7f6f4;
    cursor: pointer;
    border-radius: 0.3rem;
    box-shadow: #00000029 0px 2px 4px, #0000003b 0px 2px 4px;
    border-bottom: solid 1px #ada9a9ab;
    border-right: solid 1px #ada9a9ab;
    border-left: solid 1px #ada9a9ab;
    scroll-behavior: smooth;
    height: 25vh;
    width: 14vw;
    h4 {
        text-align: center;
        font-weight: 500;
        font-size: 14px;
    }
    span {
        color: $primary-bg-white-sub-text;
        font-size: 12px;
    }
    img {
        box-shadow: #00000029 0px 2px 4px, #0000003b 0px 2px 4px;
        border-top-left-radius: 0.2rem;
        border-top-right-radius: 0.2rem;
        max-width: 13vw;
        max-height: 20vh;
    }
    &:hover {
        transition: all 0.2s ease-in-out;
        opacity: 0.4;
        background-color: $primary-bg-dark;
    }
}

.page-title {
    font-size: 20px;
}

.image-container {
    height: 100%;
}

.inactive-shadow {
    opacity: 0.2;
    cursor: default;
}

.input-light-search {
    padding: 10px;
    border-radius: 0.5rem;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    width: 80%;
    background-color: #e7e5e5ab;
    border: none !important;
    &::placeholder {
        color: $primary-bg-white-sub-text;
    }
}

.button-search {
    cursor: pointer;
    font-size: 30px;
    color: #9c9c9cab;
    border: none;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;

    .icon-search {
        display: flex;
    }
}

.form-input-search {
    width: 12vw;
}

// Filter ***************************
.top-filter {
    height: fit-content;
    padding-top: 30px !important;
    h3 {
        padding-left: 10px;
        color: black;
    }
}

.dashboard-all-projects-submit {
    background-color: $primary-bg-dark-link-hover;
    padding: 5px 15px 5px 15px;
    color: #c09d5b;
    margin-left: 5px;
    font-size: 20px;
    border-radius: 5px;
    font-weight: 300;
}

.filter-form-container {
    height: -webkit-fill-available;

    p {
        color: $primary-bg-white-sub-text;
    }

    ::-webkit-scrollbar {
        display: unset;
    }
}
.design-container {
    h5 {
        color: $accent-gold;
        padding-top: 20px !important;
    }
}

.button-container-filters {
    justify-content: center;
    height: fit-content;

    button {
        bottom: 0px;
        height: fit-content;
        width: 70%;
        padding: 10px 10px 10px 10px;
        color: $primary-bg-dark-title;
        font-weight: 500;
        border-radius: 0.2rem;
        border: none;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        &:hover {
            transition: all 0.2s ease-in-out;
            background-color: $primary-bg-dark-text;
            color: $primary-bg-white;
        }
    }

    .submit {
        background-color: $accent-gold;
    }
    .reset {
        background-color: $primary-bg-dark-link-hover;
        color: #f7f6f4;
    }
}
