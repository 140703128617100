@import '../../../../../variables/variables.scss';
@import './projectModal';

.all-projects-container {
    border-bottom: 2px solid $primary-bg-dark-title;
}
.personal-active {
    color: $accent-gold !important;
    padding-bottom: 10px;
    transition: 0.3s ease-in-out;
}

.personal-active::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 40%;
    border-bottom: 4px solid $accent-gold;
    transition: 0.3s ease-in-out;
}
.personal-not-active::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0%;
    border-bottom: 4px solid $accent-gold;
    transition: 0.3s ease-in-out;
}

.personal-section-links a:hover::before {
    cursor: pointer;
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border-bottom: 4px solid $accent-gold;
    transition: 0.3s ease-in-out;
}
.personal-section-links {
    display: flex;
    justify-content: space-between;
    margin-left: 30px;
    width: 320px;
    a {
        text-decoration: none;
        position: relative;
        left: 20px;
        color: $primary-bg-dark-text;
        padding-bottom: 10px;
    }
    a:hover {
        cursor: pointer;
    }
}

.dashboard-all-projects {
    min-height: 70vh;
}

.form-bar-button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 10vh;
}
.button-toggler {
    background-color: #ecebe7;
    border: 1px $line-light solid;
    border-radius: 5px;
}
.projects__bar {
    margin: 1vh 0 3vh 0;
}

.dashboard-all-projects-submit:hover {
    cursor: pointer;
}

// ~~~~~~~~~~~~~~~TABLE SECTION~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
.dashboard-all-projects-table {
    width: 100%;
    color: $primary-bg-dark-title;
}

.table-headers {
    font-size: $primary-text-fs;
    border-bottom: 2px solid $primary-bg-dark-title;
    height: 6vh;
    font-weight: 700;
}

.all__projects {
    height: 65vh;
}

.projects__table {
    thead {
        top: 0;
        z-index: 2;
        position: sticky;
        background: white;
        border-bottom: 2px solid $primary-bg-dark-title;
    }
}

.projects-table-dynamic-row {
    border-bottom: 1px solid $line-gray;
    font-size: $table-header-fs;
    width: 100%;
    height: 6vh;
}

.projects-table-dynamic-row:hover {
    background: $accent-dark-gray;
}

.projects-table-dynamic-name {
    text-align: left;
    margin-left: 15px;
    font-size: $table-header-fs;
    text-indent: 20px;
}

.projects-table-name {
    padding-left: 20px;
    width: 40%;
}

.projects-table-designer {
    width: 20%;
}

.projects-table-region {
    width: 15%;
}

.projects-table-status {
    width: 20%;
}

.projects-table-dots {
    width: 15%;
}

.projects-table-dynamic-status {
    span {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        border-radius: 0.5rem;
        padding: 2px 6px;
        font-size: 11px;
        font-weight: bold;
        width: 7rem;
        color: $primary-bg-dark-button-text;
    }
}

.bs-three-dots-container {
    cursor: pointer;
    display: flex;
    padding: 6px;
    font-size: 18px;
    color: $primary-bg-light-grey;
}

.bs-three-dots-container:hover {
    border-radius: 50%;
    background: $primary-bg-lt-grey;
}

.align-modal-dots {
    display: flex;
}

.project-mini-modal {
    position: absolute;
    z-index: 1;
    background: $primary-bg-dark-title;
    right: 60px;
    color: $primary-bg-white;
    border-radius: 3px;
    display: flex;
    flex-direction: column;

    div {
        padding: 10px 25px;
        display: flex;
        align-items: center;
        font-size: 18px;
    }
    .project-mini-modal-link:hover {
        background-color: #ffffff27;
        cursor: pointer;
    }
    p {
        padding-left: 10px;
        margin: 0;
        font-size: $sub-text-fs;
        color: $primary-bg-white;
    }
    span {
        padding-left: 10px;
    }
    .doc-type {
        border-top: solid 1px $primary-bg-dark-text;
        font-weight: 300;
        font-size: $med-text;
        opacity: 0.8;
    }
}

table {
    border-collapse: collapse;
}

//Showing items blank of blank
.pagination {
    position: absolute;
    right: 10px;
}

.project-pagination {
    right: 25px !important;
}

.table-showing {
    width: fit-content;
    position: absolute;
    margin-top: 20px;
    color: $primary-bg-dark-title;
}

// ~~~~~~~~~~~~~~~~~`PAGINATION LINKS~~~~~~~~~~~~~~
.page-item {
    list-style: none;
    display: inline;
    margin-left: 10px;
}

.page-link {
    list-style: none;
    color: $primary-bg-dark-title;
    float: left;
    padding: 4px;
    text-decoration: none;
    transition: background-color 0.3s;
    cursor: pointer;
}

.active-page {
    font-weight: bold;
    border-bottom: 3px solid $accent-gold;
}

.skip-link {
    list-style: none;
    color: $primary-bg-dark-title;
    float: left;
    padding: 4px;
    width: fit-content;
    text-decoration: none;
    transition: background-color 0.3s;
    cursor: pointer;
    word-spacing: 5px;
}

.pages-list {
    font-size: $sub-text-fs;
    width: 100%;
}

.page-link:hover {
    background-color: $primary-bg-lt-grey;
}

.arrow-pagination {
    position: relative;
    top: 2px;
}

#arrow-pag-before {
    padding: 0 8px;
}

#arrow-pag-next {
    padding: 0 8px;
}
.table-top {
    display: flex;
    justify-content: space-between;
}
.processing {
    display: inline;
    color: $accent-gold;
    box-shadow: 0 0 10px inset black;
    border-radius: 40px;
    padding: 0 20px;
}
.process-none {
    display: none;
}
