@import '../../../variables/variables.scss';

.form__group {
    position: relative;
    padding: 15px 0 0;
    width: 50%;
}
.inventory-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .head-left {
        display: flex;
        flex-direction: column;
        height: 18vh;
    }
    .head-right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        width: 40%;
        height: 18vh;
        .editor-contain {
            width: 100%;
            padding-top: 10px;

            .edit-input {
                font-family: inherit;
                width: 100%;
                border: 0;
                border-bottom: 2px solid $line-gray;
                outline: 0;
                font-size: 14px;
                color: $primary-bg-dark-text;
                padding: 7px 0;
                background: transparent;
                &::placeholder {
                    font-style: italic;
                    font-weight: 300;
                }
                cursor: pointer;
            }
            .form__label {
                position: relative;
                top: -50px;
            }
            .edit-input:focus {
                ~ .form__label {
                    position: relative;
                    top: -62px;
                    display: block;
                    transition: 0.2s;
                    font-size: 12px;
                    color: #c09d5b;
                    font-weight: 700;
                }
                font-weight: 700;
                border-width: 3px;
                border-color: $line-light;
            }
        }
    }
}
.inv-header {
    font-size: 2em;
}

.form__field {
    font-family: inherit;
    width: 100%;
    margin: 10px 0;
    border: 0;
    border-bottom: 2px solid $line-gray;
    outline: 0;
    font-size: 14px;
    color: $primary-bg-dark-text;
    padding: 7px 0;
    background: transparent;

    &::placeholder {
        color: transparent;
    }

    &:placeholder-shown ~ .form__label {
        font-size: 14px;
        cursor: text;
        top: 20px;
    }
}

.form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 14px;
}

.form__field:focus {
    ~ .form__label {
        position: absolute;
        top: 0;
        display: block;
        transition: 0.2s;
        font-size: 12px;
        color: #c09d5b;
        font-weight: 700;
    }
    padding-bottom: 6px;
    font-weight: 700;
    border-width: 3px;
    border-color: $line-light;
}

.form__field {
    &:required,
    &:invalid {
        box-shadow: none;
    }
    color: #000000;
}

.list__group {
    position: relative;
    padding: 15px 0 0;
    width: 20%;
}

.settings_container {
    margin-top: 50px;
    height: 85vh;
    overflow-y: scroll;
}

.add__materials {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.images__label {
    width: 100px;
}

.new-material-button {
    background: $accent-gold;
    border: none;
    border-radius: 3px 0px 0px 3px;
    padding: 10px 10px;
    margin-left: 15px;
    max-width: 140px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    cursor: pointer;
}

.delete-material-button {
    border: none;
    padding: 10px 10px;
    background: #3f3c39;
    color: white;
    border-radius: 0px 3px 3px 0px;
    margin-right: 15px;
    cursor: pointer;
}

.delete-material-button-without-add {
    border-radius: 3px !important;
    margin-left: 15px !important;
}

.list-input::file-selector-button {
    background: $accent-gold;
    border: none;
    border-radius: 0 3px 3px 0;
    padding: 6px 25px;
    min-width: 140px;
    color: white;
    cursor: pointer;
    font-size: 14px;
}

.add__btn {
    background: $primary-bg-dark-title;
    border: none;
    border-radius: 3px 0 0 3px;
    padding: 7px 5px;
    margin-left: 15px;
    color: $accent-gold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    font-size: 14px;
}

.material__list {
    font-family: inherit;
    width: 60%;
    margin-top: 11px;
    border: 0;
    border-radius: 0px 3px 3px 0px;
    border-bottom: 2px solid $line-gray;
    outline: 0;
    background: transparent;
    font-size: 14px;
    color: $primary-bg-dark-text;
    padding: 7px 0;
    text-align: center;
}

.imgAttachment {
    height: 250px;
    width: 400px;
    margin: 25px 10px;
}

.choose__files {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.inventory-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.inventory_form_container {
    overflow-y: auto;
}

.inventory-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.tabs {
    border-radius: 0 8px 0 0;
    overflow: hidden;
}

.tab {
    width: 100%;
    color: $primary-bg-dark-text;
    overflow: hidden;
    &-label {
        display: flex;
        justify-content: space-between;
        padding: 1em;
        background: $primary-bg-dark;
        font-weight: bold;
        cursor: pointer;
        /* Icon */
        &:hover {
            background: darken($primary-bg-dark, 10%);
        }
        &::after {
            content: '\276F';
            width: 1em;
            height: 1em;
            text-align: center;
            transition: all 0.35s;
        }
    }
    &-content {
        max-height: 0;
        padding: 0 1em;
        color: $primary-bg-dark-text;
        background: white;
        transition: all 0.35s;
    }
    &-close {
        display: flex;
        justify-content: flex-end;
        padding: 1em;
        font-size: 0.75em;
        background: $primary-bg-dark;
        cursor: pointer;
        &:hover {
            background: darken($primary-bg-dark, 10%);
        }
    }
}

#chck1,
#chck2,
#chck3,
#chck4,
#chck5,
#chck6,
#chck7,
#chck8,
#chck9 {
    display: none;
}

// :checked
input:checked {
    + .tab-label {
        background: darken($primary-bg-dark, 10%);
        &::after {
            transform: rotate(90deg);
        }
    }
    ~ .tab-content {
        max-height: 200vh;
        padding: 1em;
    }
}

input:read-only {
    color: #000000;
}

.edit-button-container {
    display: flex;
    justify-content: center;
    width: 100%;
    .inventory-btn {
        @include button($accent-gold, #fff);
        margin: 25px 0px;
        cursor: pointer;
    }
    .cancel-button {
        @include button($primary-bg-white-sub-text, $primary-bg-white-text);
        margin: 25px 0px;
        margin-right: 10px;
        cursor: pointer;
    }
}
.file-contain {
    width: fit-content;
    margin: 5px;
    .img_lst {
        margin-top: -30px;
        position: relative;
        top: 53px;
        left: -5px;
        border-radius: 0px 0px 3px 0px;
    }
}
.file-row {
    display: flex;
    overflow-x: scroll;
    width: 100%;
}

.pdf-document2 {
    margin: 10px 5px;
    padding: 5px;
    height: 300px;
    .pdf-contain {
        position: relative;
        border: 1px solid;
        .pdf_lst {
            position: relative;
            border-radius: 0px 0px 3px 0px;
            left: -15px;
            top: 0px;
        }
    }
}

.un-selected-active {
    transition: all 0.2s ease-in-out;
    border: none;
    background-color: $primary-bg-dark;
    &:hover {
        transition: all 0.2s ease-in-out;
        background-color: $primary-bg-dark-text;
        color: $primary-bg-white;
    }
}
.selected-active {
    transition: all 0.2s ease-in-out;
    background-color: $accent-gold;
    border: 1px $line-dark solid;
    color: $primary-bg-white;
}

.inv-togl {
    background-color: #ecebe7;
    border: 1px $line-light solid;
    border-radius: 5px;
    width: fit-content;
}

@include media-lg {
    .file-row {
        width: 60vw;
        overflow-x: scroll;
    }
}
@include media-sm {
    .file-row {
        width: 57vw;
        overflow-x: scroll;
    }
    .inventory-container {
        width: 60vw;
    }
}
