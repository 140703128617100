@import '../../../../../variables/variables.scss';
.proposal-container {
    border: 3px solid $primary-bg-dark-title;
    border-radius: 2px;
    width: 100vw;
}
.no-wrap {
    text-wrap: nowrap;
}

.header-section {
    display: flex;
    justify-content: space-between;
    max-height: 10vh;
    min-height: 10vh;
    // padding: 1% 2%;
    padding: 0px 20px;
    border-bottom: 2px solid $primary-bg-dark-title;
    background: #23395d;
    h1:first-child {
        color: $accent-gold;
    }
    h1:nth-child(2) {
        color: $primary-bg-white;
    }
}

.proposal-attachments {
    max-width: 90vw;
    margin-left: auto;
    margin-right: auto;
}

.table-contain {
    margin-top: 2vh;
    margin-bottom: 2vh;
    margin-left: auto;
    margin-right: auto;
    width: 90vw;

    .table-border {
        table,
        th,
        td {
            border: 1px solid $primary-bg-dark-title;
            border-collapse: collapse;
        }
        table {
            width: 100%;
        }
        th {
            font-size: smaller;
            font-family: $font-family-medium;
            text-align: center;
            width: fit-content;
        }
        td {
            font-size: x-small;
            font-family: $font-family-thin;
            text-align: center;
            width: fit-content;

            span,
            p {
                padding-left: 0.25rem;
                padding-right: 0.25rem;
            }
        }

        .text-left {
            text-align: left;
        }

        .mini-header {
            height: 75px;
        }

        .list {
            display: flex;
            justify-content: space-between;
        }
        .option-list {
            min-width: 180px;
        }
        .bold {
            font-family: $font-family;
        }
    }
}

.pdf-document {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    max-width: 90vw;
}

.pdf-page {
    margin: 10px 10px 0px 0px;
    max-width: 80vw;
}

.react-pdf__Page__canvas {
    margin: 0 auto;
    width: 90% !important;
    height: 100% !important;
}

@media print {
    @page {
        size: landscape;
        // margin: 50px 0 0 0 ;
    }
}
