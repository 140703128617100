.projects-bottom-half {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: $primary-bg-dark;
}

.all-project-view-main-container {
    margin-top: 10vh;
    color: $primary-bg-dark-text;
    width: 100%;
}

.button-filter-container {
    width: 100%;
    button {
        cursor: pointer;
    }
}

.all-project-button {
    transition: all 0.2s ease-in-out;
    background-color: $accent-gold;
    border: 1px $line-dark solid;
    padding: 12px 16px 12px 16px;
    color: $primary-bg-white;
}

.your-projects-button {
    transition: all 0.2s ease-in-out;
    background-color: $accent-gold;
    border: 1px $line-dark solid;
    padding: 12px 16px 12px 16px;
    color: $primary-bg-white;
}

.type-project-btn {
    transition: all 0.2s ease-in-out;
    border: none;
    background-color: $primary-bg-dark;
    padding: 12px 16px 12px 16px;
    &:hover {
        transition: all 0.2s ease-in-out;
        background-color: $primary-bg-dark-text;
        color: $primary-bg-white;
    }
}
