@import '../../../variables/variables.scss';

// === Modal Background ====
.new-project-modal-background {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

// === Main container ===
.new-project-modal-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: 1rem auto;
    padding: 1rem 5rem;
    border-radius: 5px;
    background-color: $primary-bg-white;
    color: $primary-bg-dark;
    z-index: 1;
}

.attachments-modal-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    background-color: $primary-bg-white;
    color: $primary-bg-dark;
    z-index: 1;
}

// === Close modal ---> "X" button ===

.modal-title-close-btn button {
    position: absolute;
    left: 15px;
    background-color: transparent;
    border: none;
    font-size: $primary-text-fs;
    color: $primary-bg-dark-title;
}

.modal-title-close-btn button:hover {
    color: $primary-bg-white-text;
    cursor: pointer;
}

// === Modal Title ===
.modal-title-new-project {
    color: $primary-bg-dark-title;
    text-align: center;
}

label {
    font-size: $small-text;
}

// === Modal Inputs ===
.new-project-modal-inputs {
    @include input-field;
}

.new-projects-dropdowns-container select {
    border: 1px solid $primary-bg-light-grey;
    border-radius: 5px;
    padding: 10px 15px;
    font-size: $sub-text-fs;
    color: $primary-bg-light-grey;
    transition: all 400ms ease;
    width: 90%;
}

.new-project-modal-labels {
    font-weight: bold;
    color: $primary-bg-dark-title;
}

.max-text-span {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.max-text {
    position: relative;
    top: -20px;
    margin-right: 5px;
    font-weight: 500;
    font-size: $sub-text-fs;
    color: $primary-bg-dark;
}

// === Modal footer ---> Buttons ===
.new-project-modal-container .new-project-modal-footer {
    display: flex;
    justify-content: space-evenly;
}

.new-project-modal-container .new-project-modal-footer button {
    @include button($accent-gold, $primary-bg-dark-title);
    cursor: pointer;
}

.new-project-modal-container .new-project-modal-footer button:hover {
    transition: all 0.2s ease-in-out;
    background-color: $primary-bg-dark-text;
    color: $primary-bg-white;
}
