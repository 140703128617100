@import '../../../variables/variables.scss';

.navbar-container {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 10vh;
    background-color: $primary-bg-dark-nav;
    border-bottom: $line-height solid $line-dark;
    box-shadow: $box-shadow;
    display: flex;
    align-items: center;
    z-index: 10;

    .logo-container {
        svg {
            height: 8vh;
            width: auto;
        }
    }

    button {
        border: none;
        background-color: transparent;
        color: $accent-gold;
        font-size: 12px;
        position: absolute;
        top: 5px;
        right: 0;
    }
    button:hover {
        cursor: pointer;
        font-weight: 600;
    }
}

.navbar-links-container {
    margin-right: 20px;
    display: flex;
    justify-content: space-evenly;
}

.navbar-links {
    @include nav-link;
    color: $primary-bg-light-nav-text;
}

.navbar-user-container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: 20px;
    color: $primary-bg-light-nav-text;
    div {
        font-size: 14px;
    }
    .navbar-user-hi {
        font-weight: 100;
    }
    .navbar-user-name {
        color: $accent-gold;
    }
    .logout-icon {
        margin-left: 2em;
    }
    .logout-icon:hover {
        cursor: pointer;
    }
}

.navbar-vertical-divider {
    display: flex;
    align-self: center;
    @include vertical-line;
}

.active {
    font-weight: 400;
    padding-bottom: 40px;
    color: $accent-gold;
}
