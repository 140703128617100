@import '../../../variables/variables.scss';

.alert-modal-background {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.alert-modal-card {
    position: fixed;
    left: 50%;
    top: 30%;
    transform: translate(-50%, -50%);
    width: 450px;
    height: 200px;
    margin: 1rem auto;
    text-align: center;
    padding: 1rem 5rem;
    border-radius: 5px;
    background-color: $primary-bg-white;
    color: $primary-bg-dark-title;
    z-index: 1;
}

.alert-modal-card-title {
    text-align: center;
    font-weight: 600;
}

.alert-modal-button {
    position: absolute;
    top: 67%;
    width: 22%;
    height: 15%;
    left: 15em;
    background-color: $accent-gold;
    font-size: $primary-text-fs;
    color: $primary-bg-dark-title;
    border-radius: 5px;
    border: none;
}

.alert-modal-button button:hover {
    color: $primary-bg-white-sub-text;
    cursor: pointer;
}
