@import '../../../variables/variables.scss';

.new_room_modal_footer {
    display: flex;
    justify-content: space-evenly;

    .reset-button {
        transition: all 0.2s ease-in-out;
        border: none;
        background-color: $primary-bg-dark;
        color: $primary-bg-dark-title;
        padding: 15px 80px;
        border-radius: 5px;
    }

    .filter-modal-background {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1;
    }

    .new-room-modal-button {
        transition: all 0.2s ease-in-out;
        background-color: $accent-gold;
        padding: 15px 50px;
        border-radius: 5px;
        border: none;
        color: $primary-bg-dark-title;
    }

    .filter-modal-container {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 450px;
        height: 400px;
        margin: 1rem auto;
        padding: 1rem 5rem;
        border-radius: 5px;
        background-color: white;
        color: #101010;
    }

    button:hover {
        transition: all 0.2s ease-in-out;
        background-color: $primary-bg-dark-text;
        color: $primary-bg-white;
    }
}
