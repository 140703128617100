@import '../../../../../variables/variables.scss';

.room-container {
    position: relative;
    display: flex;
    align-items: center;
    margin: 40px 40px 10px 0;
    overflow-x: scroll;
}

.your-rooms {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}

.your-rooms-section {
    height: 70vh;
    .your-projects-none {
        flex-grow: 1;
        text-align: center;
        span {
            color: #777777;
            font-size: 20px;
        }
    }
}

.room-details-block {
    position: absolute;
    display: flex;
    align-items: center;
    bottom: 0;
    height: 50px;
    width: 100%;
    background-color: rgba(112, 111, 111, 0.219);
    span {
        position: relative;
        top: -5px;
        margin-left: 27.5%;
        color: black;
    }
    .view-details-chevron {
        position: relative;
        top: 1px;
        font-size: 10px;
        padding-left: 8px;
    }
}

.cardRoom-divider {
    align-self: center;
    height: 0.5px;
    width: 80%;
    margin-top: 15px;
    background-color: black;
}

// media queries small devices
@media (max-width: 800px) {
    .room-container {
        margin: 0;
    }

    .your-rooms {
        margin-top: 0rem;
    }

    .your-rooms-section {
        height: 70vh;
        .other-none {
            display: none;
        }
    }
}

// your projects
.your-rooms-section {
    height: 70vh;
    button {
        background-color: transparent;
        color: $primary-bg-dark-link;
        font-size: 16px;
        border: none;
        margin-left: 20px;
        margin-right: 10px;
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .your-projects-icons {
        margin-left: auto;
        color: $primary-bg;
        font-size: 20px;
        position: absolute;
        top: 5px;
        right: 0;
    }
    #your-project-icons-left {
        padding-right: 10px;
    }
    .your-projects-buttons {
        font-size: 25px;
    }
    .your-projects-buttons:hover {
        cursor: pointer;
        color: rgba(63, 60, 57, 0.219);
    }
}
