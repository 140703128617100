@import './variables/variables.scss';
@import '../node_modules/bootstrap/dist/css/bootstrap-grid.css';

* {
    font-family: $font-family;
}

body {
    margin: 0;
}

.statusColorConfigureDesign {
    background-color: $accent-grayish-red;
    fill: $accent-grayish-red;
}

.statusColorRFPCompleted {
    background-color: $accent-pink-shade-granite;
    fill: $accent-pink-shade-granite;
}

.statusColorSavedProjects {
    background-color: $accent-grayish-orange;
}

.statusColorOnHold {
    background-color: $accent-gold;
}

.statusColorAwarded {
    background-color: $accent-light-grayish-yellow;
    fill: $accent-light-grayish-yellow;
}

.statusColorTemplateNew {
    background-color: $accent-dark-gray;
}

.background-unset {
    background-color: unset;
}

.project-title-with-status-icon {
    display: flex;
    align-items: baseline;
    font-size: 22px;
    color: black;
}

.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.submit-icon {
    margin-right: 6px;
}

.text-center {
    text-align: center;
    text-align: -webkit-center;
    justify-content: center;
}

button {
    cursor: pointer;
}

.back-to-project {
    font-weight: bold;

    .chevron-icon {
        margin-top: 1%;
        padding-right: 0.5em;
    }

    a,
    button {
        text-decoration: none;
        font-size: 14px;
        color: black;
        cursor: pointer;
        display: flex;
        align-items: center;
    }

    a:hover,
    button:hover {
        color: #ada9a9ab;
    }
}

.back-button-container {
    height: 7vh;
}

.archive-icon {
    font-size: 1.6em;
    color: #949494;
}

.archive-icon:hover {
    cursor: pointer;
}

.clone-icon {
    font-size: 1.6em;
    color: #949494;
}

.clone-icon:hover {
    cursor: pointer;
}

.edit-icon {
    font-size: 1.6em;
    color: #949494;
}

.edit-icon:hover {
    cursor: pointer;
}

.container-filter {
    max-height: 90vh;
    position: fixed;
    z-index: 13;
    top: 10vh;
    bottom: 0;
    right: 0;
    transition: 0.5s;
    background-color: #ecebe7;
    border-left: #c09d5b 1px solid;
    border-top: #c09d5b 1px solid;
    box-shadow: rgba(63, 60, 57, 0.1607843137) 0px 3px 6px,
        rgba(63, 60, 57, 0.231372549) 0px 3px 6px;
    overflow-y: scroll;
}

.carousel-wrapper {
    display: inline-block;
    width: 50%;
    grid-area: carousel;
}

.grey {
    color: #949494;
}

.text-bold {
    font-weight: bold;
}

.text-italic {
    font-style: italic;
}

.back-to-projects {
    display: inline-flex;

    .chevron-icon {
        margin-right: 10px;
        position: relative;
        top: 2px;
    }

    a {
        margin-top: 12px;
        margin-left: 10px;
        text-decoration: none;
        font-size: 12px;
        color: #808080;
        position: relative;
        z-index: 2;
    }

    .back-to-all-projects {
        transition: all 0.2s ease-in-out;
        border: none;
        cursor: pointer;
        background-color: $primary-bg-dark;

        &:hover {
            transition: all 0.2s ease-in-out;
            color: $primary-bg-dark-text;
        }
    }
}

.back-to-projects:hover {
    cursor: pointer;
}

.cursor-pointer {
    cursor: pointer;
}

.collapsed {
    &::after {
        content: '\276F';
        display: inline-block;
        width: 1em;
        height: 1em;
        text-align: center;
        margin-left: 5px;
    }
}

.expanded {
    &::after {
        content: '\276F';
        display: inline-block;
        width: 1em;
        height: 1em;
        text-align: center;
        margin-left: 5px;
        transform: rotate(90deg);
    }
}

.search-input {
    min-width: 15vw;

    .form__field {
        &::placeholder {
            color: $primary-bg-white-sub-text;
            font-style: italic;
        }
    }
}
