@import '../../../variables/variables.scss';
@import './modalAttach';
@import './projectView';

.projects-top-half {
    display: flex;
    flex-direction: row;
    height: 100vh;
    background-color: $primary-bg-dark;
}

//   project summary component~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
.project-summary-container {
    margin-top: 10vh;
    background-color: $primary-bg-dark;
    border-right: 1px $line-dark solid;
    height: fit-content;
}

.project-summary-top-bar {
    margin-left: 20px;
    margin-top: 10px;
    display: flex;
    width: 100%;
}

.circle-icon {
    margin-left: 15px;
    font-size: 15px;
}

.project-summary-date {
    font-size: $sub-text-fs;
    margin-top: -15px;
    color: #808080;
}

.project-summary-icons {
    display: inline-flex;
    align-self: center;
    margin-left: 20px;
    width: 40%;
}

.status {
    font-size: 15px;
}

.project-summary-text-container {
    margin: 0 2em;
    font-size: $sub-text-fs;
}

.project-summary-description-text {
    font-weight: 100;
    font-size: $small-text;
}

// Project attachments component~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
.project-attachments-container {
    padding-top: 140px;
    background-color: $primary-bg-dark;
    width: 100%;
    color: $primary-bg-dark-link;
}

.project-attachments-top-bar {
    margin-left: 15px;
    display: inline-flex;
    height: 60px;
}

.project-attachment {
    padding-right: 20px;
    color: $primary-bg-dark-link;
}

.paperclip-icon {
    border-radius: 50%;
    background-color: rgb(41, 40, 40);
    margin-top: 17px;
    margin-left: 20px;
    padding: 5px;
}

.paperclip-icon:hover {
    cursor: pointer;
}

.attach-file-text {
    font-size: 8px;
    margin-top: 25px;
    margin-left: 5px;
}

.project-attachments-table-container {
    color: $primary-bg-dark-title;
    overflow: hidden;
    overflow-y: scroll;
}

.attachments-file-name {
    width: 80%;
    font-size: $med-text;
    text-align: left;
    border-bottom: 1px solid rgb(39, 39, 40);
}

.attachments-file-remove {
    width: 20%;
    font-size: $med-text;
    text-align: center;
}

.attachments-table {
    width: 100%;

    thead {
        top: 0;
        position: sticky;
        background: rgb(236, 235, 231);
        text-align: left;
        font-size: $med-text;
    }

    td {
        font-size: 12px;
        padding: 0px 15px;
        border-top: 1px $line-dark solid;
        border-bottom: 1px $line-dark solid;
    }

    th {
        font-size: 12px;
        padding: 0px 15px;
    }
}

.attachments-dynamic-row:hover {
    transition: 200ms ease;
    background-color: #ada9a9ab;
}

.attachments-dynamic-row {
    cursor: pointer;
}

.file-file-name {
    font-size: $sub-text-fs;
    border-top: 1px solid rgb(39, 39, 40);
    font-weight: 200;
    padding: 15px;
    font-size: $small-text;
}

.file-file-remove {
    font-size: $small-text;
    border-top: 1px solid rgb(39, 39, 40);
    text-align: center;
}

.file-file-remove:hover {
    cursor: pointer;
    color: red;
}

.project-attachments-view-all {
    float: right;
    margin-right: 10%;
    font-size: $small-text;
}

.project-attachments-view-all:hover {
    cursor: pointer;
}

.projects-navbar-container {
    color: $primary-bg-dark-text;
    display: flex;
    align-items: center;
}

.projects-link {
    font-size: 1.2em;
}

.projects-active {
    color: $accent-gold !important;
    font-weight: 600;
}

.projects-link:hover {
    cursor: pointer;
}

.room-button {
    color: white;
    background-color: $primary-bg;
    height: 35px;
    font-size: 16px;
}

.add-sign {
    font-size: 22px;
}

.no-room-text {
    margin: 0 auto;
    text-align: center;
    font-size: 12px;
    padding-top: 150px;
    width: 70%;
}

// navigation links active

.projects-active {
    color: $accent-gold !important;
    font-weight: 600;
}

.proposal_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.print_btn {
    padding: 10px;
    border-radius: 5px;
    background: $accent-gold;
    max-width: fit-content;
    align-self: center;
    border: none;
    color: $primary-bg-dark-title;
    margin-bottom: 20px;
}

.print_btn:hover {
    transition: 0.2s ease-in-out;
    background: $primary-bg-light-grey;
    color: $primary-bg-white;
}
