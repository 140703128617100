@import '../../../variables/variables.scss';

.dashboard-container {
    display: flex;
    flex-direction: column;
    background-color: $primary-bg-dark;
    margin-top: 10vh;
    height: 100vh;
    h4 {
        color: $primary-bg-dark-title;
        font-size: $primary-title-fs;
    }
    a:hover {
        cursor: pointer;
    }
}

.overview-vertical-divider {
    @include vertical-line;
    height: 35px;
    width: 1px;
    background-color: $accent-gold;
}

button.dashboard-new-project-button {
    background-color: $primary-bg;
    color: $primary-bg-light-button-text;
    border-radius: 5px;
}

button.dashboard-new-project-button:hover {
    background-color: $primary-bg-light-button-hover;
    border-color: $primary-bg-light-button-hover;
    transition: all 0.2s ease-in-out;
    color: $primary-bg-light-button-text;
}

.dashboard-new-project-sub-text {
    color: $primary-bg-light-button-text;
    position: relative;
    top: 0.1.1vh;
}

.submit-icon {
    color: $primary-bg-light-button-text;
    position: relative;
    margin-bottom: -0.17vh;
}

.dashboard-project-overview {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.overview-label {
    font-size: 15px;
}

.num {
    font-size: 20px;
    color: $accent-gold;
    font-weight: bold;
}

.overview-icon-main {
    display: flex;
    width: 30px;
    height: auto;
    color: $primary-bg-light-grey;
}

.overview-configure-title {
    display: flex;
    color: $primary-bg;
}

.overview-configure-num {
    display: flex;
}

.overview-completed-title {
    display: flex;
    color: $primary-bg;
}

.overview-completed-num {
    display: flex;
}

.overview-saved-title {
    display: flex;
    color: $primary-bg;
}

.overview-saved-num {
    display: flex;
}

.overview-hold-title {
    display: flex;
    color: $primary-bg;
}

.overview-hold-num {
    display: flex;
    grid-area: hold_num;
}

.overview-template-title {
    display: flex;
    grid-area: template_title;
    color: $primary-bg;
}

.overview-template-num {
    display: flex;
}

.your-projects-section {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 25vh;
    gap: 4%;
    flex-direction: row;
    justify-content: center;
    margin-left: 5.25%;
    margin-top: 1.5vh;
    .your-projects-none {
        flex-grow: 1;
        text-align: center;
        span {
            color: #777777;
            font-size: 13px;
        }
    }
}

.single-project {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    height: 100%;
    min-width: 250px;
    max-width: 250px;
    border-radius: 5px;
    padding-bottom: 2vh;
    span {
        padding: 10px 15px 0 15px;
        font-size: 12px;
        color: $primary-bg-dark-title;
    }
    .card-divider {
        align-self: center;
        height: 0.5px;
        width: auto;
        margin-top: 15px;
        background-color: #000000;
    }
    h3 {
        padding: 5px 15px;
        color: $primary-bg-dark-title;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        margin-top: auto;
        margin-bottom: auto;
        padding-bottom: 50px;
    }
    .view-details-block {
        position: absolute;
        display: flex;
        align-items: center;
        bottom: 0;
        height: 50px;
        width: 100%;
        background-color: rgb(0 0 0 / 22%);
        span {
            position: relative;
            top: -5px;
            color: #fff;
            letter-spacing: 1px;
            margin-left: auto;
        }
        .view-details-chevron {
            position: relative;
            top: 1px;
            font-size: 10px;
            padding-left: 8px;
        }
    }
}

.your-rooms-section > .single-project {
    padding-bottom: 2vh;
    height: 25vh;

    .view-details-block {
        position: absolute;
        bottom: 0;
    }
}

.your-projects-section > .single-project {
    justify-content: space-between;
}

.single-project:hover {
    transition: all 400ms ease;
    opacity: 0.8;
    cursor: pointer;
    h3 {
        color: black;
    }
}

.archive-icon {
    font-size: 1.2em;
    color: $primary-bg-dark-text;
}
.archive-icon:hover {
    cursor: pointer;
}
