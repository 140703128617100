@import '../../../variables/variables.scss';

.users-table-title {
    margin-top: 10px;
    h1 {
        margin: 0px;
    }
    p {
        font-size: 18px;
    }
}

.new-user-button {
    background: $accent-gold;
    border: none;
    border-radius: 3px;
    padding: 10px 25px;
    min-width: 140px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    cursor: pointer;
}

.users-table-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.users-table {
    width: 100%;
    display: flex;
    flex-direction: column;
    color: $primary-bg-dark-title;
}

.users-table-headers {
    font-size: $table-header-fs;
    font-weight: 700;
    display: grid;
    grid-template-columns: 2fr 2fr 1fr 1fr;
    border-bottom: 2px solid $primary-bg-dark-link;
    color: $primary-bg-dark-title;
    td {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
}
.settings-head {
    border-radius: 3px 3px 0px 0px;
    cursor: pointer;
}
.settings-head:hover {
    box-shadow: inset 0 0 5px 5px rgba(0, 0, 0, 0.676);
}
.remove-td {
    justify-content: center !important;
}

.user-options-button {
    position: relative;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $primary-bg-dark-text;
    margin: 0 10px 0 0;
}

.user-table-row {
    font-size: 14px;
    font-weight: 500;
    display: grid;
    grid-template-columns: 2fr 2fr 1fr 1fr;
    border-bottom: 2px solid $line-gray;
    color: $primary-bg-dark-title;
    th {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    td {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .remove-button-td {
        justify-content: center;
    }
}
.mini-modal-contain {
    position: absolute;
    z-index: 1;
    background: $primary-bg-dark-title;
    bottom: 100%;
    right: 0%;
    color: $primary-bg-white;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    div {
        padding: 10px 25px;
        display: flex;
        align-items: center;
        font-size: 18px;
    }
    .mini-modal-link:hover {
        background-color: #ffffff27;
        cursor: pointer;
    }
    p {
        padding-left: 10px;
        margin: 0;
        font-size: $sub-text-fs;
        color: $primary-bg-white;
    }
    span {
        padding-left: 10px;
    }
    .doc-type {
        border-top: solid 1px $primary-bg-dark-text;
        font-weight: 300;
        font-size: $med-text;
        opacity: 0.8;
    }
}
.active-user {
    color: $accent-gold;
}
#toast {
    position: absolute;
    display: none;
    width: 50%;
    background-color: #333;
    top: 10px;
    height: 80px;
    z-index: 11;
    .message-wrapper {
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
        color: #ddd;
    }
}
@include media-sm {
    .mini-modal-contain {
        right: 5%;
    }
}
@include media-lg {
    .mini-modal-contain {
        right: 6%;
    }
}
@include media-xl {
    .mini-modal-contain {
        right: 9%;
    }
}
